import { useI18n } from 'vue-i18n';

import {
	Message,
	notifyDialogError,
	notifyDialogInfo,
	notifyDialogSuccess,
	notifyDialogWarning,
	notifyToastError,
	notifyToastInfo,
	notifyToastSuccess,
	notifyToastWarning
} from '~/services';

import { AxiosApiError } from '../api';

const defaultSuccessMessage: Message = {
	title: 'common.feedback.success.title',
	text: 'common.feedback.success.text'
};

const defaultErrorMessage: Message = {
	title: 'common.feedback.error.title',
	text: 'common.feedback.error.text'
};

export function useToasts(): {
	axiosError: (err: AxiosApiError) => void;
	error: (msg?: string | Message) => void;
	info: (msg: string | Message) => void;
	success: (msg?: string | Message) => void;
	warning: (msg: string | Message, duration?: number) => void;
} {
	const { t } = useI18n();

	const translateMessage = (msg: Message) => ({ title: t(msg.title), text: t(msg.text) });
	const error = (msg?: string | Message) => notifyToastError(msg ?? translateMessage(defaultErrorMessage));
	const axiosError = (err: AxiosApiError) => {
		// prevent error toast when user is disconnected
		if (err?.response?.status !== 401) {
			error({ title: t('common.feedback.error.title'), text: err?.response?.data?.error });
		}
	};
	const success = (msg?: string | Message) => notifyToastSuccess(msg ?? translateMessage(defaultSuccessMessage));

	return {
		axiosError,
		error,
		info: notifyToastInfo,
		success,
		warning: notifyToastWarning
	};
}

export function useDialogs(): {
	error: (msg?: string | Message) => void;
	info: (msg: string | Message) => void;
	success: (msg?: string | Message) => void;
	warning: (msg: string | Message) => void;
} {
	const { t } = useI18n();

	const translateMessage = (msg: Message) => ({ title: t(msg.title), text: msg.text != undefined ? t(msg.text) : undefined });
	const error = (msg?: string | Message) => notifyDialogError(msg ?? translateMessage(defaultErrorMessage));
	const success = (msg?: string | Message) => notifyDialogSuccess(msg ?? translateMessage(defaultSuccessMessage));

	return {
		error,
		info: notifyDialogInfo,
		success,
		warning: notifyDialogWarning
	};
}

// TODO this could be useful as we nearly always catch error, toast and return EMPTY
// export function catchAndNotifyAxiosError<T>(): MonoTypeOperatorFunction<T> {
// 	const { axiosError } = useToasts();
// 	return catchError((err: AxiosApiError) => {
// 		axiosError(err);
// 		return EMPTY;
// 	});
// }

// TODO see if a notification observable is useful
// export function notifyResult<T, E extends Error = Error>(
// 	success: string | ((data: T) => string),
// 	error: string | ((err: E) => string)
// ): MonoTypeOperatorFunction<T> {
// 	return function (source: Observable<T>): Observable<T> {
// 		return new Observable(subscriber => {
// 			// return unsubscribe method
// 			return source.subscribe({
// 				next(value) {
// 					const msg = getMsg(value, success);
// 					notifySuccess(msg);
// 					subscriber.next(value);
// 				},
// 				error(err) {
// 					const msg = getMsg(err, error);
// 					notifyError(msg);
// 					subscriber.error(error);
// 				},
// 				complete() {
// 					subscriber.complete();
// 				}
// 			});
// 		});
// 	};
// }
//

// function getMsg<T>(data: T, msgFactory: string | ((data: T) => string)): string {
// 	if (typeof msgFactory === 'function') {
// 		return msgFactory(data);
// 	} else {
// 		return msgFactory;
// 	}
// }
